import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { DentalFirstService } from '../../../dental-first.service';

@Component({
  selector: 'app-results-item',
  templateUrl: './results-item.component.html',
  styleUrls: ['./results-item.component.css']
})
export class ResultsItemComponent implements OnInit {

  @Input() practice;
  isCollapsed: boolean = true;
  loadingEvents: boolean = false;
  dentists: any[] = [];

  practiceCalendar: any[] = [];
  events: any[] = [];
  returnedArray: any[];
  selectedDentist: any;
  datePipe = new DatePipe("en");

  constructor(
    private dentalFirstService: DentalFirstService
  ) { }

  ngOnInit() {
    //this.returnedArray = this.timeSlots.slice(0, 7);
  }

  bookAnAppointment(event: Event) {
    event.preventDefault();
    if (!this.isCollapsed) {
      this.isCollapsed = !this.isCollapsed;
      return;
    }
    let today = this.datePipe.transform(new Date(), "yyyy-MM-dd");
    this.loadingEvents = true;
    //here we are going to get practice calendar from the backend and then
    this.dentalFirstService.getEvents(this.practice.id, today).subscribe((practiceCalendar: any[]) => {
      this.loadingEvents = false;
      this.isCollapsed = !this.isCollapsed
      this.practiceCalendar = practiceCalendar;
      this.initialiseDentist(practiceCalendar);
      this.selectedDentist = this.dentists.find((dentist) => dentist.isSelected == true)
      let selectedCalendar: any = practiceCalendar.find((calendar) => calendar.dentist.id == this.selectedDentist.id);
      this.events = Object.values(selectedCalendar.events);
      this.returnedArray = this.events.slice(0, 7);//.forEach(val => console.log(val));
    }, (error) => {
      this.loadingEvents = false;
      console.log("error ==> ", error);
    })
  }

  pageChanged(event: PageChangedEvent): void {
    const startItem = (event.page - 1) * event.itemsPerPage;
    const endItem = event.page * event.itemsPerPage;
    this.returnedArray = this.events.slice(startItem, endItem);
  }

  initialiseDentist(practiceCalendar: any[]) {
    this.dentists = practiceCalendar.map((obj, index) => {
      return Object.assign({
        id: obj.dentist.id,
        fullName: obj.dentist.fullName,
        isSelected: index == 0 ? true : false,
      });
    })
  }

  selectNewDentist(selectedDentist: any) {
    console.log("new dentist selected ==> ", selectedDentist);
    let currentObj: any = this.dentists.find((obj) => obj.isSelected == true);
    currentObj.isSelected = false;
    selectedDentist.isSelected = true;
    this.selectedDentist = selectedDentist;

    let selectedCalendar: any = this.practiceCalendar.find((calendar) => calendar.dentist.id == selectedDentist.id);
    this.events = Object.values(selectedCalendar.events);
    this.returnedArray = this.events.slice(0, 7);//.forEach(val => console.log(val));
  }

}

