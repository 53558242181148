import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-school-book-page',
  templateUrl: './school-book-page.component.html',
  styleUrls: ['./school-book-page.component.css']
})
export class SchoolBookPageComponent implements OnInit, OnDestroy {
  slideChangeMessage = '';
  text1: any = {
    title: 'title 1',
    description: 'description 1 hshshs shshs sshshs s <br/> shshshshs shshshshs sshshshshss hsshshshs shshsh'
  };
  text2: any = {
    title: 'title 2',
    description: 'description 2'
  };
  text3: any = {
    title: 'title 3',
    description: 'description 3'
  };
  ourText: any = { title: '', description: '' };

  slides = [
    { image: 'assets/img/portfolio/portfolio-details-1.jpg', text: "" },
    { image: 'assets/img/portfolio/portfolio-details-2.jpg', text: "" },
    { image: 'assets/img/portfolio/portfolio-details-3.jpg', text: "" }
  ];

  constructor(
    private appService: AppService,
    private router: Router
  ) { }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.appService.inSchoolBookMode.next(false);
  }

  getStarted(event: Event) {
    event.preventDefault();
    this.appService.inGetStartedMode.next(true);
    this.appService.getStartedValue.next("sb");
    this.router.navigate(['/get-started/school-book-form']);
  }

  log(event: number) {
    this.slideChangeMessage = `Slide has been switched: ${event}`;
    console.log("event number => ", event);
    if (event === 0) {
      this.ourText = this.text1;
    } else if (event === 1) {
      this.ourText = this.text2;
    } else {
      this.ourText = this.text3;
    }
  }

}
