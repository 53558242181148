import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: '[app-bulk-sms-feature]',
  templateUrl: './bulk-sms-feature.component.html',
  styleUrls: ['./bulk-sms-feature.component.css']
})
export class BulkSmsFeatureComponent implements OnInit {

  isCollapsed: boolean = false;
  @Input() feature;

  constructor() { }

  ngOnInit() {
    if(this.feature.id != 1) {
      this.isCollapsed = true;
    }
  }
}
