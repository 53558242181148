import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { config } from './core/object-nation.config';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  inHomeMode = new BehaviorSubject<boolean>(false);
  inAboutMode = new BehaviorSubject<boolean>(false);
  inServicesMode = new BehaviorSubject<boolean>(false);
  inSchoolBookMode = new BehaviorSubject<boolean>(false);
  inDentalFirstMode = new BehaviorSubject<boolean>(false);
  inPickADentistMode = new BehaviorSubject<boolean>(false);
  inContactMode = new BehaviorSubject<boolean>(false);
  inGetStartedMode = new BehaviorSubject<boolean>(false);
  inBulkSmsMode = new BehaviorSubject<boolean>(false);

  openMobileMenu = new BehaviorSubject<boolean>(false);
  inBulkSmsConsoleMode = new BehaviorSubject<boolean>(false);

  getStartedValue = new BehaviorSubject<any>(null);

  constructor(
    private router: Router,
    private httpClient: HttpClient
  ) {

  }

  onContactUs(obj: any) {
    return this.httpClient.post<any>(config.APP_URL + '/contact-lead', obj).pipe(map((response: any) => {
      return response;
    }))
  }
}
