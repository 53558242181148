import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-landing-page-header',
  templateUrl: './landing-page-header.component.html',
  styleUrls: ['./landing-page-header.component.css']
})
export class LandingPageHeaderComponent implements OnInit {

  constructor(
    public appService: AppService,
    private router: Router
  ) { }

  ngOnInit() {
  }

  getStartedPage(event: Event) {
    event.preventDefault();
    this.appService.inGetStartedMode.next(true);
    this.router.navigate(['/get-started']);
  }

  dentalFirst(event: Event) {
    event.preventDefault();
    this.appService.inDentalFirstMode.next(true);
    this.router.navigate(['/dental-first']);
  }

  schoolBook(event: Event) {
    event.preventDefault();
    this.appService.inSchoolBookMode.next(true);
    this.router.navigate(['/school-book']);
  }

}
