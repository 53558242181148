import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { config } from 'src/app/core/object-nation.config';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DentalFirstService {

  latitude = new BehaviorSubject<string>(null);
  longitude = new BehaviorSubject<string>(null);

  practices = new BehaviorSubject<any[]>(null);

  constructor(
    private httpClient: HttpClient
  ) { }


  getPractices(lat: any, lng: any) {
    let params = new HttpParams();
    params = new HttpParams()
      .set('lon', lng)
      .set('lat', lat);
    return this.httpClient.get<any>(config.APP_URL + '/practice/search-by-location', {params}).pipe(map((response: any) => {
      return response;
    }))
  }

  getEvents(practiceId: any, startDate: any) {
    let params = new HttpParams();
    params = new HttpParams()
      .set('id', practiceId)
      .set('startDate', startDate);
    return this.httpClient.get<any>(config.APP_URL + '/practice/calendar', {params}).pipe(map((response: any) => {
      return response;
    }))
  }

  bookAppointment(obj: any) {
    return this.httpClient.post<any>(config.APP_URL + '/appointment-request', obj).pipe(map((response: any) => {
      return response;
    }))
  }
}
