import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-our-products',
  templateUrl: './our-products.component.html',
  styleUrls: ['./our-products.component.css']
})
export class OurProductsComponent implements OnInit {

    sbFeatures: any[] = [
      {id: 1, i: "01", title: "Our SchoolBook Web Application Portal", description: "The Schoolbook web application portal is the backbone of schoolbook platform, that automates school processes and seamlessly links the school to guardians and students. Say goodbye to paper and manual processes and increase efficiency."},
      {id: 2, i: "02", title: "Our SchoolBook Mobile Application", description: "Often in real cases, the parent sponsors the education but remains in the dark about the school or child’s performance, results, attendance or the various events at the school. The parent gets communication here and there on various aspects. Schoolbook mobile app enables the parents to stay in touch and be a part of the school and their child’s school life on a daily basis and take required action as desired."}
    ];

  constructor(
    private router: Router,
    private appService: AppService,
  ) { }

  ngOnInit() {
  }

  dentalFirstPage(event: Event) {
    event.preventDefault();
    this.appService.inDentalFirstMode.next(true);
    this.router.navigate(['/dental-first'])    
  }

  schoolBookPage(event: Event) {
    event.preventDefault();
    this.appService.inSchoolBookMode.next(true);
    this.router.navigate(['/school-book'])    
  }

}
