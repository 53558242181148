import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-get-started-header',
  templateUrl: './get-started-header.component.html',
  styleUrls: ['./get-started-header.component.css']
})
export class GetStartedHeaderComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
