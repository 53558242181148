import { Component, OnInit, OnDestroy } from '@angular/core';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-about-page',
  templateUrl: './about-page.component.html',
  styleUrls: ['./about-page.component.css']
})
export class AboutPageComponent implements OnInit, OnDestroy {

  constructor(
    private appService: AppService
  ) { }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.appService.inAboutMode.next(false);
  }


}
