import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { Router } from '@angular/router';

@Component({
  selector: '[app-menu]',
  templateUrl: './app-menu.component.html',
  styleUrls: ['./app-menu.component.css']
})
export class AppMenuComponent implements OnInit {

  constructor(
    public appService: AppService,
    private router: Router
  ) { }

  ngOnInit() {
  }

  onGetStarted(event: Event) {
    event.preventDefault();
    if (this.appService.inBulkSmsMode.value) {
      //this.appService.inBulkSmsConsoleMode.next(true);
      this.appService.inGetStartedMode.next(true);
      this.appService.getStartedValue.next("sms");
      //this.router.navigate(['/bulk-sms-console']);
      this.router.navigate(['/get-started']);
    } else {
      if (this.appService.inSchoolBookMode.value) {
        this.appService.inGetStartedMode.next(true);
        this.appService.getStartedValue.next("sb");
        this.router.navigate(['/get-started/school-book-form']);
      } else if (this.appService.inDentalFirstMode.value) {
        this.appService.inGetStartedMode.next(true);
        this.appService.getStartedValue.next("df");
        this.router.navigate(['/get-started/dental-first-form']);
      } else {
        this.appService.inGetStartedMode.next(true);
        this.appService.getStartedValue.next("df");
        this.router.navigate(['/get-started']);
      }
    }
  }

  onContactUs(event: Event) {
    event.preventDefault();
    this.appService.inContactMode.next(true);
    this.router.navigate(['/contact']);
  }

  homePage(event: Event) {
    event.preventDefault();
    this.appService.inHomeMode.next(true);
    this.router.navigate(['/home']);
  }

  aboutPage(event: Event) {
    event.preventDefault();
    this.appService.inAboutMode.next(true);
    this.router.navigate(['/about']);
  }

  servicesPage(event: Event) {
    event.preventDefault();
    this.appService.inServicesMode.next(true);
    this.router.navigate(['/services']);
  }

  schoolBookPage(event: Event) {
    event.preventDefault();
    this.appService.inSchoolBookMode.next(true);
    this.router.navigate(['/school-book']);
  }

  dentalFirstPage(event: Event) {
    event.preventDefault();
    this.appService.inDentalFirstMode.next(true);
    this.router.navigate(['/dental-first']);
  }

  contactPage(event: Event) {
    event.preventDefault();
    this.appService.inContactMode.next(true);
    this.router.navigate(['/contact']);
  }

  bulkSmsPage(event: Event) {
    event.preventDefault();
    this.appService.inBulkSmsMode.next(true);
    this.router.navigate(['/bulk-sms']);
  }
}
