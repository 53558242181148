import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-school-book-overview',
  templateUrl: './school-book-overview.component.html',
  styleUrls: ['./school-book-overview.component.css']
})
export class SchoolBookOverviewComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
