import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LandingPageComponent } from './features/landing-page/landing-page.component';
import { AboutPageComponent } from './features/about-page/about-page.component';
import { ContactPageComponent } from './features/contact-page/contact-page.component';
import { HomePageComponent } from './home-page/home-page.component';
import { SchoolBookPageComponent } from './features/school-book-page/school-book-page.component';
import { DentalFirstPageComponent } from './features/dental-first-page/dental-first-page.component';
import { BulkSmsPageComponent } from './features/bulk-sms-page/bulk-sms-page.component';
import { GetStartedPageComponent } from './features/get-started-page/get-started-page.component';
import { BulkSmsCreateAccountComponent } from './features/get-started-page/bulk-sms-create-account/bulk-sms-create-account.component';
import { SchoolBookEnquiryFormComponent } from './features/get-started-page/school-book-enquiry-form/school-book-enquiry-form.component';
import { DentalFirstEnquiryFormComponent } from './features/get-started-page/dental-first-enquiry-form/dental-first-enquiry-form.component';
import { MyHomeComponent } from './features/dental-first-page/my-home/my-home.component';
import { PickADentistComponent } from './features/dental-first-page/pick-a-dentist/pick-a-dentist.component';
import { SearchResultsComponent } from './features/dental-first-page/pick-a-dentist/search-results/search-results.component';


const routes: Routes = [
  { path: "", component: HomePageComponent, children: [
    { path: '', redirectTo: 'home', pathMatch: 'full' },
    { path: 'home', component: LandingPageComponent },
    { path: "about", component: AboutPageComponent },
    { path: "bulk-sms", component: BulkSmsPageComponent },
    { path: "school-book", component: SchoolBookPageComponent },
    { path: "dental-first", component: DentalFirstPageComponent, children: [
      { path: '', component: MyHomeComponent },
      { path: 'pick-a-dentist', component: PickADentistComponent, children: [
        { path: '', component: SearchResultsComponent }
      ] }
    ] },
    { path: "contact", component: ContactPageComponent },
    { path: "get-started", component: GetStartedPageComponent, children: [
      { path: '', component: DentalFirstEnquiryFormComponent },
      { path: 'dental-first-form', component: DentalFirstEnquiryFormComponent },
      { path: 'school-book-form', component: SchoolBookEnquiryFormComponent },
      { path: 'bulk-sms', component: BulkSmsCreateAccountComponent }      
    ] },
    { path: "bulk-sms-console", loadChildren: "./bulk-sms-console/bulk-sms-console.module#BulkSmsConsoleModule" },
  ] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    //useHash: true,
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled',
    scrollOffset: [0, 0]
  })], //, { useHash: true }
  exports: [RouterModule]
})
export class AppRoutingModule { }
