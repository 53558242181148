import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LandingPageComponent } from './features/landing-page/landing-page.component';
import { AboutPageComponent } from './features/about-page/about-page.component';
import { ContactPageComponent } from './features/contact-page/contact-page.component';
import { RouterModule } from '@angular/router';
import { HomePageComponent } from './home-page/home-page.component';
import { ServicesPageComponent } from './features/services-page/services-page.component';
import { SchoolBookPageComponent } from './features/school-book-page/school-book-page.component';
import { DentalFirstPageComponent } from './features/dental-first-page/dental-first-page.component';
import { AppHeaderComponent } from './shared/layout/app-header/app-header.component';
import { AppMenuComponent } from './shared/layout/app-menu/app-menu.component';
import { LandingPageHeaderComponent } from './shared/layout/app-header/landing-page-header/landing-page-header.component';
import { ContactPageHeaderComponent } from './shared/layout/app-header/contact-page-header/contact-page-header.component';
import { AboutPageHeaderComponent } from './shared/layout/app-header/about-page-header/about-page-header.component';
import { DentalfirstPageHeaderComponent } from './shared/layout/app-header/dentalfirst-page-header/dentalfirst-page-header.component';
import { SchoolbookPageHeaderComponent } from './shared/layout/app-header/schoolbook-page-header/schoolbook-page-header.component';
import { ServicesPageHeaderComponent } from './shared/layout/app-header/services-page-header/services-page-header.component';
import { AppFooterComponent } from './shared/layout/app-footer/app-footer.component';
import { AboutComponent } from './features/landing-page/about/about.component';
import { OurProductsComponent } from './features/landing-page/our-products/our-products.component';
import { OurServicesComponent } from './features/landing-page/our-services/our-services.component';
import { OurPhotoGalleryComponent } from './features/landing-page/our-photo-gallery/our-photo-gallery.component';
import { SchoolBookOverviewComponent } from './features/school-book-page/school-book-overview/school-book-overview.component';
import { SchoolBookFeaturesComponent } from './features/school-book-page/school-book-features/school-book-features.component';
import { AboutOverviewComponent } from './features/about-page/about-overview/about-overview.component';
import { AboutServicesComponent } from './features/about-page/about-services/about-services.component';
import { GetStartedPageComponent } from './features/get-started-page/get-started-page.component';
import { GetStartedHeaderComponent } from './shared/layout/app-header/get-started-header/get-started-header.component';
import { BulkSmsPageComponent } from './features/bulk-sms-page/bulk-sms-page.component';
import { BulkSmsHeaderComponent } from './shared/layout/app-header/bulk-sms-header/bulk-sms-header.component';
import { BulkSmsCreateAccountComponent } from './features/get-started-page/bulk-sms-create-account/bulk-sms-create-account.component';
import { SchoolBookEnquiryFormComponent } from './features/get-started-page/school-book-enquiry-form/school-book-enquiry-form.component';
import { DentalFirstEnquiryFormComponent } from './features/get-started-page/dental-first-enquiry-form/dental-first-enquiry-form.component';
import { OverviewComponent } from './features/get-started-page/overview/overview.component';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { BulkSmsConsoleHeaderComponent } from './shared/layout/app-header/bulk-sms-console-header/bulk-sms-console-header.component';
import { MyHomeComponent } from './features/dental-first-page/my-home/my-home.component';
import { DentalFirstOverviewComponent } from './features/dental-first-page/my-home/dental-first-overview/dental-first-overview.component';
import { DentalFirstFeaturesComponent } from './features/dental-first-page/my-home/dental-first-features/dental-first-features.component';
import { PickADentistComponent } from './features/dental-first-page/pick-a-dentist/pick-a-dentist.component';
import { SearchResultsComponent } from './features/dental-first-page/pick-a-dentist/search-results/search-results.component';
import { AgmCoreModule } from '@agm/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { ResultsItemComponent } from './features/dental-first-page/pick-a-dentist/search-results/results-item/results-item.component';
import { PickADentistHeaderComponent } from './shared/layout/app-header/pick-a-dentist-header/pick-a-dentist-header.component';
import { TimeSlotComponent } from './features/dental-first-page/pick-a-dentist/search-results/results-item/time-slot/time-slot.component';
import { SlotItemComponent } from './features/dental-first-page/pick-a-dentist/search-results/results-item/time-slot/slot-item/slot-item.component';
import { BookAppointmentModalComponent } from './features/dental-first-page/pick-a-dentist/book-appointment-modal/book-appointment-modal.component';
import { HttpClientModule } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { DentistActionBtnComponent } from './features/dental-first-page/pick-a-dentist/search-results/results-item/dentist-action-btn/dentist-action-btn.component';
import { OurClientsComponent } from './features/dental-first-page/my-home/our-clients/our-clients.component';
import { SbProductFeaturesComponent } from './features/landing-page/our-products/sb-product-features/sb-product-features.component';
import { SbbProductFeaturesComponent } from './features/school-book-page/school-book-features/sbb-product-features/sbb-product-features.component';
import { MobileAppFeaturesComponent } from './features/school-book-page/mobile-app-features/mobile-app-features.component';
import { BulkSmsFeatureComponent } from './features/bulk-sms-page/bulk-sms-feature/bulk-sms-feature.component';
import { SortByPipe } from './shared/pipes/sort-by.pipe';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';

@NgModule({
  declarations: [
    AppComponent,
    LandingPageComponent,
    AboutPageComponent,
    ContactPageComponent,
    HomePageComponent,
    ServicesPageComponent,
    SchoolBookPageComponent,
    DentalFirstPageComponent,
    AppHeaderComponent,
    AppMenuComponent,
    LandingPageHeaderComponent,
    ContactPageHeaderComponent,
    AboutPageHeaderComponent,
    DentalfirstPageHeaderComponent,
    SchoolbookPageHeaderComponent,
    ServicesPageHeaderComponent,
    AppFooterComponent,
    AboutComponent,
    OurProductsComponent,
    OurServicesComponent,
    OurPhotoGalleryComponent,
    SchoolBookOverviewComponent,
    SchoolBookFeaturesComponent,
    DentalFirstOverviewComponent,
    DentalFirstFeaturesComponent,
    AboutOverviewComponent,
    AboutServicesComponent,
    GetStartedPageComponent,
    GetStartedHeaderComponent,
    BulkSmsPageComponent,
    BulkSmsHeaderComponent,
    BulkSmsCreateAccountComponent,
    SchoolBookEnquiryFormComponent,
    DentalFirstEnquiryFormComponent,
    OverviewComponent,
    BulkSmsConsoleHeaderComponent,
    MyHomeComponent,
    PickADentistComponent,
    SearchResultsComponent,
    ResultsItemComponent,
    PickADentistHeaderComponent,
    TimeSlotComponent,
    SlotItemComponent,
    BookAppointmentModalComponent,
    DentistActionBtnComponent,
    OurClientsComponent,
    SbProductFeaturesComponent,
    SbbProductFeaturesComponent,
    MobileAppFeaturesComponent,
    BulkSmsFeatureComponent,
    SortByPipe
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBpMcmoSmL_9J4cmJZz71GUq6VIp8e-_Dk',
      libraries: ['places']
    }),
    FormsModule,
    ReactiveFormsModule,
    BsDatepickerModule.forRoot(),
    PaginationModule.forRoot(),
    TabsModule.forRoot(),
    ModalModule.forRoot(),
    HttpClientModule,
    NgSelectModule,
    CarouselModule.forRoot(),
    AccordionModule.forRoot(),
    CollapseModule.forRoot(),
    ToastrModule.forRoot()
  ],
  entryComponents: [
    BookAppointmentModalComponent
  ],
  providers: [{
    provide: LocationStrategy,
    useClass: HashLocationStrategy,
  }],
  bootstrap: [AppComponent]
})
export class AppModule { }
