import { Component, HostListener } from '@angular/core';
import { AppService } from './app.service';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { Location } from '@angular/common';
import { NgSelectConfig } from '@ng-select/ng-select';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'object-nation-web';

  constructor(

    public appService: AppService,
    private router: Router,
    private location: Location,
    private config: NgSelectConfig
  ) {
    this.config.notFoundText = 'Custom not found';
    this.config.appendTo = 'body';
    // set the bindValue to global config when you use the same 
    // bindValue in most of the place. 
    // You can also override bindValue for the specified template 
    // by defining `bindValue` as property
    // Eg : <ng-select bindValue="some-new-value"></ng-select>
    this.config.bindValue = 'value';
    if (this.location.isCurrentPathEqualTo('/home') || this.location.isCurrentPathEqualTo('/')) this.appService.inHomeMode.next(true);
    if (this.location.isCurrentPathEqualTo('/about')) this.appService.inAboutMode.next(true);
    if (this.location.isCurrentPathEqualTo('/bulk-sms')) this.appService.inBulkSmsMode.next(true);
    if (this.location.isCurrentPathEqualTo('/school-book')) this.appService.inSchoolBookMode.next(true);
    if (this.location.isCurrentPathEqualTo('/dental-first')) this.appService.inDentalFirstMode.next(true);
    if (this.location.isCurrentPathEqualTo('/dental-first/pick-a-dentist')) this.appService.inPickADentistMode.next(true);
    if (this.location.isCurrentPathEqualTo('/contact')) this.appService.inContactMode.next(true);
    if (this.location.isCurrentPathEqualTo('/get-started')) this.appService.inGetStartedMode.next(true);
    if (this.location.isCurrentPathEqualTo('/get-started/dental-first-form')) this.appService.inGetStartedMode.next(true);
    if (this.location.isCurrentPathEqualTo('/get-started/school-book-form')) this.appService.inGetStartedMode.next(true);
    if (this.location.isCurrentPathEqualTo('/get-started/bulk-sms')) this.appService.inGetStartedMode.next(true);
    if (this.location.isCurrentPathEqualTo('/bulk-sms-console')) this.appService.inBulkSmsConsoleMode.next(true);
  }

  @HostListener('window:popstate', ['$event'])
  onPopState(event: Event) {
    if (this.location.isCurrentPathEqualTo('/home') || this.location.isCurrentPathEqualTo('/')) this.appService.inHomeMode.next(true);
    if (this.location.isCurrentPathEqualTo('/about')) this.appService.inAboutMode.next(true);
    if (this.location.isCurrentPathEqualTo('/bulk-sms')) this.appService.inBulkSmsMode.next(true);
    if (this.location.isCurrentPathEqualTo('/school-book')) this.appService.inSchoolBookMode.next(true);
    if (this.location.isCurrentPathEqualTo('/dental-first')) this.appService.inDentalFirstMode.next(true);
    if (this.location.isCurrentPathEqualTo('/dental-first/pick-a-dentist')) this.appService.inPickADentistMode.next(true);
    if (this.location.isCurrentPathEqualTo('/contact')) this.appService.inContactMode.next(true);
    if (this.location.isCurrentPathEqualTo('/get-started')) this.appService.inGetStartedMode.next(true);
    if (this.location.isCurrentPathEqualTo('/get-started/dental-first-form')) this.appService.inGetStartedMode.next(true);
    if (this.location.isCurrentPathEqualTo('/get-started/school-book-form')) this.appService.inGetStartedMode.next(true);
    if (this.location.isCurrentPathEqualTo('/get-started/bulk-sms')) this.appService.inGetStartedMode.next(true);
    if (this.location.isCurrentPathEqualTo('/bulk-sms-console')) this.appService.inBulkSmsConsoleMode.next(true);
  }
}
