import { Component, OnInit, OnDestroy } from '@angular/core';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-services-page',
  templateUrl: './services-page.component.html',
  styleUrls: ['./services-page.component.css']
})
export class ServicesPageComponent implements OnInit, OnDestroy {

  constructor(
    private appService: AppService
  ) { }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.appService.inServicesMode.next(false);
  }

}
