import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: '[app-sb-product-features]',
  templateUrl: './sb-product-features.component.html',
  styleUrls: ['./sb-product-features.component.css']
})
export class SbProductFeaturesComponent implements OnInit {

  isCollapsed: boolean = false;
  @Input() feature;

  constructor() { }

  ngOnInit() {
    if(this.feature.id != 1) {
      this.isCollapsed = true;
    }
  }

}
