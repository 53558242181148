import { Component, OnInit, OnDestroy } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-bulk-sms-page',
  templateUrl: './bulk-sms-page.component.html',
  styleUrls: ['./bulk-sms-page.component.css']
})
export class BulkSmsPageComponent implements OnInit, OnDestroy {

  sbFeatures: any[] = [
    {id: 1, i: "01", title: 'Smart', description: 'A cloud-hosted, user-friendly portal and the wizard-driven code generation makes integration into your existing systems easy'},
    {id: 2, i: "02", title: 'Scalable', description: 'An SMS platform that scales with you, allowing you to send a high volume of messages delivered reliably and on time '},
    {id: 3, i: "03", title: 'Secure', description: 'Ensure that conversations between your business and customers remain 100% private with default end-to-end encryption '},
  ];

  constructor(
    private appService: AppService,
    private router: Router
  ) { }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.appService.inBulkSmsMode.next(false);
  }

  onGetStarted(event: Event) {
    event.preventDefault();
    this.appService.inGetStartedMode.next(true);
    this.appService.getStartedValue.next('sms')
    this.router.navigate(['/get-started'])
  }

}
