import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-bulk-sms-header',
  templateUrl: './bulk-sms-header.component.html',
  styleUrls: ['./bulk-sms-header.component.css']
})
export class BulkSmsHeaderComponent implements OnInit {

  constructor(
    private appService: AppService,
    private router: Router
  ) { }

  ngOnInit() {
  }

  onGetStarted(event: Event) {
    event.preventDefault();
    this.appService.inGetStartedMode.next(true);
    this.appService.getStartedValue.next('sms');
    this.router.navigate(['/get-started'])
  }

}
