import { Component, OnInit, OnDestroy } from '@angular/core';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.css']
})
export class LandingPageComponent implements OnInit, OnDestroy {

  constructor(
    private appService: AppService
  ) { 

  }

  ngOnInit() {
    console.log("in home mode");
  }

  ngOnDestroy() {
    this.appService.inHomeMode.next(false);
  }

}
