import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-contact-page-header',
  templateUrl: './contact-page-header.component.html',
  styleUrls: ['./contact-page-header.component.css']
})
export class ContactPageHeaderComponent implements OnInit {

  constructor(
    public appService: AppService,
  ) { }

  ngOnInit() {
  }

}
