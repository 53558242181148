import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-get-started-page',
  templateUrl: './get-started-page.component.html',
  styleUrls: ['./get-started-page.component.css']
})
export class GetStartedPageComponent implements OnInit, OnDestroy {

  radioForm: FormGroup;
  getStartedValue: any;

  constructor(
    private appService: AppService,
    private router: Router
  ) { }

  ngOnInit() {
    this.getStartedValue = this.appService.getStartedValue.value;
    this.initRadioForm(this.getStartedValue == null ? this.getStartedValue = 'df' : this.getStartedValue);
    console.log("1---value---", this.getStartedValue);
    this.routePage(this.getStartedValue);
    this.radioForm.get('radioBtn').valueChanges.subscribe((val) => {
      this.getStartedValue = val;
      this.routePage(this.getStartedValue);
    })
  }

  ngOnDestroy() {
    this.appService.inGetStartedMode.next(false);
    this.appService.getStartedValue.next(null);
  }

  initRadioForm(val: any) {
    this.radioForm = new FormGroup({
      radioBtn: new FormControl(val),
    })
  }

  routePage(val: any) {
    if(val == 'df') {
      this.router.navigate(["/get-started/dental-first-form"]);
    } else if(val == 'sb') {
      this.router.navigate(["/get-started/school-book-form"]);
    } else {
      this.router.navigate(["/get-started/bulk-sms"]);
    }
  }

}
