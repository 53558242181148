import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bulk-sms-console-header',
  templateUrl: './bulk-sms-console-header.component.html',
  styleUrls: ['./bulk-sms-console-header.component.css']
})
export class BulkSmsConsoleHeaderComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
