import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { error } from 'protractor';
import { DentalFirstService } from '../../dental-first.service';

@Component({
  selector: 'app-book-appointment-modal',
  templateUrl: './book-appointment-modal.component.html',
  styleUrls: ['./book-appointment-modal.component.css']
})
export class BookAppointmentModalComponent implements OnInit {

  title: string;
  closeBtnName: string;
  list: any[] = [];
  obj: any;
  practice: any;
  dentist: any;
  date: any;
  index: number;

  bookingForm: FormGroup;
  submitting: boolean = false;
  datePipe = new DatePipe("en");

  constructor(
    public bsModalRef: BsModalRef,
    private dentalFirstService: DentalFirstService,
    private toastr: ToastrService
  ) { }

  ngOnInit() {
    this.initForm();
    console.log("my object is ==> ", this.practice);
  }

  initForm() {
    this.bookingForm = new FormGroup({
      'fullName': new FormControl('', Validators.required),
      'phoneNumber': new FormControl('', Validators.required),
      'dateOfBirth': new FormControl('', Validators.required),
      'description': new FormControl('', Validators.required),
      'emailAddress': new FormControl(''),
      'idNumber': new FormControl('')
    })
  }

  onSubmit() {
    if (this.bookingForm.invalid) {
      this.bookingForm.markAllAsTouched();
      return;
    }
    this.submitting = true;
    let ob = this.bookingForm.value;
    let time = this.index == 0 ? this.obj.first : this.obj.second;
    ob.dateOfBirth = this.datePipe.transform(ob.dateOfBirth, "yyyy-MM-dd");
    ob.practiceId = this.practice.id;
    ob.doctorId = this.dentist.id;
    ob.startTime = this.date + " " + time;
    this.dentalFirstService.bookAppointment(ob).subscribe((result) => {
      this.bsModalRef.hide();
      this.toastr.success('Appointment request successfully created', 'Success!', {
        closeButton: true,
        progressBar: true
      });
      this.submitting = false;
    }, (error) => {
      this.toastr.error(error.error.message, 'Error!', {
        closeButton: true,
        progressBar: true
      });
      this.submitting = false;
    });

  }

}
