import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mobile-app-features',
  templateUrl: './mobile-app-features.component.html',
  styleUrls: ['./mobile-app-features.component.css']
})
export class MobileAppFeaturesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
