import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-about-page-header',
  templateUrl: './about-page-header.component.html',
  styleUrls: ['./about-page-header.component.css']
})
export class AboutPageHeaderComponent implements OnInit {

  constructor(
    private appService: AppService,
    private router: Router
  ) { }

  ngOnInit() {
  }

  contactUs(event: Event) {
    event.preventDefault();
    this.appService.inContactMode.next(true);
    this.router.navigate(['/contact']);
  }

}
