import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-dental-first-enquiry-form',
  templateUrl: './dental-first-enquiry-form.component.html',
  styleUrls: ['./dental-first-enquiry-form.component.css']
})
export class DentalFirstEnquiryFormComponent implements OnInit {

  submitting: boolean = false;
  dfForm: FormGroup;

  constructor() { }

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.dfForm = new FormGroup({
      'test': new FormControl(''),
    })
  }

  onSubmit() {
    if (this.dfForm.invalid) {
      this.dfForm.markAllAsTouched();
      return;
    }

  }

  onCancel() {

  }

}
