import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-schoolbook-page-header',
  templateUrl: './schoolbook-page-header.component.html',
  styleUrls: ['./schoolbook-page-header.component.css']
})
export class SchoolbookPageHeaderComponent implements OnInit {

  constructor(
    private appService: AppService,
    private router: Router
  ) { }

  ngOnInit() {
  }

  getStarted(event: Event) {
    event.preventDefault();
    this.appService.inGetStartedMode.next(true);
    this.appService.getStartedValue.next("sb");
    this.router.navigate(['/get-started/school-book-form']);
  }

}
