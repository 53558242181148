import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bulk-sms-create-account',
  templateUrl: './bulk-sms-create-account.component.html',
  styleUrls: ['./bulk-sms-create-account.component.css']
})
export class BulkSmsCreateAccountComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
