import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from 'src/app/app.service';

@Component({
  selector: '[app-footer]',
  templateUrl: './app-footer.component.html',
  styleUrls: ['./app-footer.component.css']
})
export class AppFooterComponent implements OnInit {

  constructor(
    public appService: AppService,
    private router: Router
  ) { }

  ngOnInit() {
  }

  onFacebook(event: Event) {
    event.preventDefault();
    //window.location.href = "https://www.facebook.com/objectnationzw";
    window.open(
      'https://www.facebook.com/objectnationzw',
      '_blank' // <- This is what makes it open in a new window.
    );
  }

}
