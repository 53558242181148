import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: '[app-sbb-product-features]',
  templateUrl: './sbb-product-features.component.html',
  styleUrls: ['./sbb-product-features.component.css']
})
export class SbbProductFeaturesComponent implements OnInit {

  isCollapsed: boolean = false;
  @Input() feature;

  constructor() { }

  ngOnInit() {
    if(this.feature.id != 1) {
      this.isCollapsed = true;
    }
  }

}
