import { MapsAPILoader } from '@agm/core';
import { Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/app.service';
import { DentalFirstService } from 'src/app/features/dental-first-page/dental-first.service';

@Component({
  selector: 'app-dentalfirst-page-header',
  templateUrl: './dentalfirst-page-header.component.html',
  styleUrls: ['./dentalfirst-page-header.component.css']
})
export class DentalfirstPageHeaderComponent implements OnInit {

  searchingNearMe: boolean = false;
  lat: any;
  lng: any;

  @ViewChild('search', { static: true })
  public searchElementRef: ElementRef;
  private geoCoder;
  locBtnDisabled: boolean = true;

  constructor(
    private router: Router,
    private dentalFirstService: DentalFirstService,
    private appService: AppService,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private toastr: ToastrService
  ) {

  }

  ngOnInit() {
    //load Places Autocomplete
    /*this.mapsAPILoader.load().then(() => {
      //this.setCurrentLocation();
      this.geoCoder = new google.maps.Geocoder;

      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();

          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          //set latitude, longitude and zoom
          this.lat = place.geometry.location.lat();
          this.lng = place.geometry.location.lng();

          this.locBtnDisabled = false;

          console.log("lat ==> ", this.lat);
          console.log("lng ==> ", this.lng);
        });
      });
    });*/
  }

  findDentist(event: Event) {
    event.preventDefault();
    if (this.searchingNearMe) {
      return;
    }
    this.searchingNearMe = true;
    if (navigator) {
      navigator.geolocation.getCurrentPosition(pos => {
        this.lng = +pos.coords.longitude;
        this.lat = +pos.coords.latitude;

        this.dentalFirstService.getPractices(this.lat, this.lng).subscribe((results) => {
          this.searchingNearMe = false;
    
          //move to the results page
          this.dentalFirstService.latitude.next(this.lat);
          this.dentalFirstService.longitude.next(this.lng);
    
          this.appService.inDentalFirstMode.next(false);
          this.appService.inPickADentistMode.next(true);
          this.dentalFirstService.practices.next(results); 
          console.log("results ==> ", results);
          this.router.navigate(['dental-first/pick-a-dentist']);
        }, (error) => {
          this.toastr.error('Error - Please contact Object Nation PL', 'Error!', {
            closeButton: true,
            progressBar: true
          });
          this.router.navigate(['dental-first/pick-a-dentist']);
          this.searchingNearMe = false;
        })
      }, (e) => {
        this.dentalFirstService.getPractices(0, 0).subscribe((results) => {
          this.searchingNearMe = false;
    
          //move to the results page
          this.dentalFirstService.latitude.next(this.lat);
          this.dentalFirstService.longitude.next(this.lng);
    
          this.appService.inDentalFirstMode.next(false);
          this.appService.inPickADentistMode.next(true);
          this.dentalFirstService.practices.next(results); 
          console.log("results ==> ", results);
          this.router.navigate(['dental-first/pick-a-dentist']);
        }, (error) => {
          this.toastr.error('Error - Please contact Object Nation PL', 'Error!', {
            closeButton: true,
            progressBar: true
          });
          this.router.navigate(['dental-first/pick-a-dentist']);
          this.searchingNearMe = false;
        })
      });
    }
  }

  findByLocation() {
    if (!(this.lat && this.lng)) {
      return;
    }
    this.locBtnDisabled = true;
    //send request to backend

    this.dentalFirstService.getPractices(this.lat, this.lng).subscribe((results) => {
      console.log("results => ", results);
      this.locBtnDisabled = false;

      //move to the results page
      this.dentalFirstService.latitude.next(this.lat);
      this.dentalFirstService.longitude.next(this.lng);

      this.appService.inDentalFirstMode.next(false);
      this.appService.inPickADentistMode.next(true);
      this.dentalFirstService.practices.next(results);
      this.router.navigate(['dental-first/pick-a-dentist']);
    }, (error) => {
      this.toastr.error('Error - Please contact Object Nation PL', 'Error!', {
        closeButton: true,
        progressBar: true
      });
      this.locBtnDisabled = false;
    })
  }
}
