import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: '[app-time-slot]',
  templateUrl: './time-slot.component.html',
  styleUrls: ['./time-slot.component.css']
})
export class TimeSlotComponent implements OnInit {

  @Input() timeSlot: any;
  @Input() practice: any;
  @Input() selectedDentist: any;
  isDisabled: boolean = true;
  slots: any[] = [];

  constructor() { }

  ngOnInit() {
    this.slots = Object.values(this.timeSlot.slots)
  }

}
