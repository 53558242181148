import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-contact-page',
  templateUrl: './contact-page.component.html',
  styleUrls: ['./contact-page.component.css']
})
export class ContactPageComponent implements OnInit, OnDestroy {

  contactUsForm: FormGroup;
  sending: boolean = false;

  constructor(
    private appService: AppService,
    private toastr: ToastrService
  ) { 
    
  }

  ngOnInit() {
    this.initForm();
  }

  ngOnDestroy() {
    this.appService.inContactMode.next(false);
  }

  initForm() {
    this.contactUsForm = new FormGroup({
      'name': new FormControl('', Validators.required),
      'emailAddress': new FormControl('', Validators.required),
      'phoneNumber': new FormControl('', Validators.required),
      'subject': new FormControl('', Validators.required),
      'message': new FormControl('', Validators.required),
    })
  }

  onSubmit() {
    if(this.contactUsForm.invalid) {
      this.contactUsForm.markAllAsTouched()
      return;
    }
    this.sending = true
    let obj = this.contactUsForm.value;
    this.appService.onContactUs(obj).subscribe((resp) => {
      this.toastr.success('Message successfully sent', 'Success!', {
        closeButton: true,
        progressBar: true
      });
      this.contactUsForm.reset();
      this.sending = false;
    }, (e) => {
      this.toastr.error('Error while sending message!', 'Error!', {
        closeButton: true,
        progressBar: true
      });
      this.sending = false;
    })
  }

}
