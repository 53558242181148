import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: '[app-dentist-action-btn]',
  templateUrl: './dentist-action-btn.component.html',
  styleUrls: ['./dentist-action-btn.component.css']
})
export class DentistActionBtnComponent implements OnInit {

  @Input() dentist: any;
  @Output() newSelection = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

  onSelect() {
    console.log("selected dentist => ", this.dentist);
    if(this.dentist.isSelected) {
      return;
    }
    this.newSelection.emit(this.dentist);
  }
}
