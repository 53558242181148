import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { BookAppointmentModalComponent } from '../../../../book-appointment-modal/book-appointment-modal.component';

@Component({
  selector: '[app-slot-item]',
  templateUrl: './slot-item.component.html',
  styleUrls: ['./slot-item.component.css']
})
export class SlotItemComponent implements OnInit {

  @Input() slot: any;
  @Input() practice: any;
  @Input() date: any;
  @Input() selectedDentist: any;
  bsModalRef: BsModalRef;

  constructor(
    private modalService: BsModalService
  ) { }

  ngOnInit() {
  }

  onMakeAppointment(obj: any, index: number) {
    console.log("--print my slot--> ", obj)
    const initialState = {
      list: [
        'Open a modal with component',
        'Pass your data',
        'Do something else',
        '...'
      ],
      title: 'Modal with component',
      practice: this.practice,
      dentist: this.selectedDentist, 
      date: this.date,
      obj: obj,
      index: index
    };
    this.bsModalRef = this.modalService.show(
      BookAppointmentModalComponent,
      {
        initialState,
        animated: true,
        keyboard: false,
        ignoreBackdropClick: true,
        class: 'modal-xl'
      }
    );
    this.bsModalRef.content.closeBtnName = 'Close';
  }

  onSelectSecondHalf() {
    console.log("second half selected for => ", this.slot);
  }
}
