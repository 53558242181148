import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-dental-first-overview',
  templateUrl: './dental-first-overview.component.html',
  styleUrls: ['./dental-first-overview.component.css']
})
export class DentalFirstOverviewComponent implements OnInit {

  constructor(
    private router: Router,
    private appService: AppService
  ) { }

  ngOnInit() {
  }

  getStarted(event: Event) {
    event.preventDefault();
    this.appService.inGetStartedMode.next(true);
    this.router.navigate(['/get-started/dental-first-form']);
  }

}
