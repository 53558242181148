import { Component, OnInit, OnDestroy } from '@angular/core';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-dental-first-page',
  templateUrl: './dental-first-page.component.html',
  styleUrls: ['./dental-first-page.component.css']
})
export class DentalFirstPageComponent implements OnInit, OnDestroy {

  constructor(
    private appService: AppService
  ) { }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.appService.inDentalFirstMode.next(false);
  }

}
