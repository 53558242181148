import { Component, ElementRef, OnInit, NgZone, ViewChild, OnDestroy } from '@angular/core';
//import { google } from '@google/maps';
import { MapsAPILoader } from '@agm/core';
import { DentalFirstService } from '../../dental-first.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-search-results',
  templateUrl: './search-results.component.html',
  styleUrls: ['./search-results.component.css']
})
export class SearchResultsComponent implements OnInit, OnDestroy {

  loading: boolean = false;
  isMedicalAid: boolean = false;
  isCash: boolean = false;
  searchForm: FormGroup;
  datePipe = new DatePipe("en");
  data: any = Object.assign({});

  latitude: number;
  longitude: number;
  address: string;
  private geoCoder;

  /*@ViewChild('search', { static: true })
  public searchElementRef: ElementRef;*/

  selectedReason: number;

  visitReasons: any[] = [
    { id: 1, name: 'Examination/Checkup' },
    { id: 2, name: 'Toothache' },
    { id: 3, name: 'Dental Caries' },
    { id: 4, name: 'Other' },
  ];

  practicesSub: Subscription;
  practices: any[] = [];
  practiceCoordinates: any[] = [];

  constructor(
    public dentalFirstService: DentalFirstService,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private router: Router,
    private appService: AppService
  ) { }

  ngOnInit() {
    //initialise form 
    this.initSearchForm();
    this.data.location = 'Mainway Meadows';
    this.data.visitReason = { id: 1, name: 'Examination/Checkup' };
    this.data.paymentMode = "medicalAid";
    this.data.preferedDate = this.datePipe.transform(new Date(), "yyyy-MM-dd");
    this.setSearchFormValues(this.data);

    //get practices and coordinates
    this.practicesSub = this.dentalFirstService.practices.subscribe((results: any[]) => {
      if(results == null) {
        this.appService.inDentalFirstMode.next(true);
        this.router.navigate(["/dental-first"]);
        return;
      }
      this.practices = results;
      console.log("practice => ", results);
      this.practiceCoordinates = results.map((obj) => {
        return  {
          'latitude': obj == undefined ? 0 : obj.geoLocation.latitude, 
          'longitude': obj == undefined ? 0 : obj.geoLocation.longitude,
          'title': obj == undefined ? '' : obj.name
        }
      })
    })

    //load Places Autocomplete
    /*this.mapsAPILoader.load().then(() => {
      //this.setCurrentLocation();
      this.geoCoder = new google.maps.Geocoder;

      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();

          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          //set latitude, longitude and zoom
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();

          console.log("place ==> ", place);
        });
      });
    });*/
  }

  ngOnDestroy() {
    //this.appService.inDentalFirstMode.next(false);
    this.appService.inPickADentistMode.next(false);
    this.dentalFirstService.practices.next(null);
    this.practicesSub.unsubscribe();
  }

  initSearchForm() {
    this.searchForm = new FormGroup({
      'location': new FormControl('', Validators.required),
      'visitReason': new FormControl(null, Validators.required),
      'paymentMode': new FormControl('', Validators.required),
      'preferedDate': new FormControl('', Validators.required),
    })
  }

  setSearchFormValues(data: any) {
    this.searchForm.patchValue({
      'location': data.location,
      'visitReason': data.visitReason,
      'paymentMode': data.paymentMode,
      'preferedDate': data.preferedDate,
    })
  }

  onSearch() {
    this.loading = true;
    setTimeout(() => {
      let obj = this.searchForm.value;
      obj.preferedDate = this.datePipe.transform(obj.preferedDate, "yyyy-MM-dd");
      let myObj: Object = obj.visitReason;
      if (!myObj.hasOwnProperty('name')) {
        obj.visitReason = this.visitReasons.find((val) => val.id == obj.visitReason);
      }
      //test to get address from coordinates
      this.getAddress(this.latitude, this.longitude);
      this.loading = false;
    }, 1500);
  }

  onMedicalAid() {
    this.isCash = false;
    if (!this.isMedicalAid) {
      this.isMedicalAid = true;
    }
  }

  onCash() {
    this.isMedicalAid = false;
    if (!this.isCash) {
      this.isCash = true;
    }
  }

  getAddress(latitude, longitude) {
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      console.log(results);
      console.log(status);
      if (status === 'OK') {
        if (results[1]) {
          this.address = results[1].formatted_address;
          console.log("formatted address 1 => ", this.address);
        } else if(results[0]) {
          this.address = results[0].formatted_address;
          console.log("formatted address 0 => ", this.address);
        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }

    });
  }

  compareFn(c1: any, c2: any): boolean {
    return c1 && c2 ? c1.id === c2.id : c1 === c2;
  }

}
