import { Component, OnInit, } from '@angular/core';
import { AppService } from '../app.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { ViewportScroller } from '@angular/common';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.css']
})
export class HomePageComponent implements OnInit {

  constructor(
    public appService: AppService,
    private router: Router,
    private viewportScroller: ViewportScroller,
  ) { }

  ngOnInit() {
  }

  toggleMenu() {
    let val = this.appService.openMobileMenu.value;
    this.appService.openMobileMenu.next(!val);
  }

  homePage(event: Event) {
    event.preventDefault();
    this.appService.inHomeMode.next(true);
    this.appService.openMobileMenu.next(false);
    this.router.navigate(['/home']);
  }

  aboutPage(event: Event) {
    event.preventDefault();
    this.appService.inAboutMode.next(true);
    this.appService.openMobileMenu.next(false);
    this.router.navigate(['/about']);
  }

  servicesPage(event: Event) {
    event.preventDefault();
    this.appService.inServicesMode.next(true);
    this.appService.openMobileMenu.next(false);
    this.router.navigate(['/services']);
  }

  schoolBookPage(event: Event) {
    event.preventDefault();
    this.appService.inSchoolBookMode.next(true);
    this.appService.openMobileMenu.next(false);
    this.router.navigate(['/school-book']);
  }

  dentalFirstPage(event: Event) {
    event.preventDefault();
    this.appService.inDentalFirstMode.next(true);
    this.appService.openMobileMenu.next(false);
    this.router.navigate(['/dental-first']);
  }

  contactPage(event: Event) {
    event.preventDefault();
    this.appService.inContactMode.next(true);
    this.appService.openMobileMenu.next(false);
    this.router.navigate(['/contact']);
  }

  bulkSmsPage(event: Event) {
    event.preventDefault();
    this.appService.inBulkSmsMode.next(true);
    this.appService.openMobileMenu.next(false);
    this.router.navigate(['/bulk-sms']);
  }

  onBackToTop(event: Event) {
    event.preventDefault();
    let elementId: string;
    if(this.appService.inHomeMode.value) {
      elementId = 'hero';
    } else {
      elementId = 'hero1';
    }
    console.log('current loc ==> ', location.pathname);
    this.viewportScroller.scrollToAnchor(elementId);
  }
}
