import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-school-book-features',
  templateUrl: './school-book-features.component.html',
  styleUrls: ['./school-book-features.component.css']
})
export class SchoolBookFeaturesComponent implements OnInit {

  sbFeatures: any[] = [
    {id: 1, i: "01", title: 'Administration Portal', description: 'Schoolbook allows schools to manage all their administration online - the overall process from student applications and admissions, student records, school timetable management and staff records. All the information is well organized and easily accessible when required. With the schoolbook system, schools can finally say goodbye to manual records and hello to a paperless environment.'},
    {id: 2, i: "02", title: 'Class & Teacher Management', description: 'This module enables the school to design and setup subjects and allocation of teachers to teach the subjects and monitor the effectiveness. The staff member allocated to teach a module can setup lesson plans, schedule, lesson contents and class assignments to students. The school can set up and monitor information with respect to its teaching staff, monitor feedback and performance of staff including attendance and which is based also on other aspect oriented performance parameters.'},
    {id: 3, i: "03", title: 'Communication ', description: 'With Schoolbook it becomes very easy to communicate in real time and have Seamless communication between the school and the parents/guardians. Schoolbook allows communication for: (1) Internal communication between the school and its teaching staff (2) External communication between the school and the parents in the form of notices, reminders, admissions, fees payments, homework etc.'},
    {id: 4, i: "04", title: 'Events Management Module', description: 'Event Management Module helps the institute in planning up the events in advance which will be notified automatically to all its stakeholders. All the events of the school are properly managed and very clear to all stakeholders such that everyone is aware well in time. All the events, exams, holidays, schedules, etc. can be viewed in one place.'},
    {id: 5, i: "05", title: 'Fees Management Module', description: 'Schoolbook helps the school in collecting fees through an online process and helps in maintaining a proper database of each and every transaction. Regular notifications are sent to parents in terms of due fees, late payment, etc. Fee module is integrated with accounting module where the income transactions are posted automatically. In-built accounts management system allows to handle entire day to day transactions of the institute.'},
    {id: 6, i: "06", title: 'Automated Timetable', description: 'Schoolbook’s automated time table generation helps the institute in creating the time table for the entire school in seconds and you are guaranteed that like no clashes between staff, subjects etc.'},
    {id: 7, i: "07", title: 'Reporting', description: 'Schoolbook generates various reports for the Head, administrator, teachers and parents depending upon their specified role. These reports are customized as per the requirements which enables the user to have the actionable data in their hand for immediate actions. The reports can be exported either to a spreadsheet or excel for further analysis.'},
  ];

  constructor() { }

  ngOnInit() {
  }

}
